<template>
  <a-modal
    v-model="addPlaceVisible"
    :title="names"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    dialogClass="batch_advertiser_modal"
  >
    <div class="add_advertiser_wrapper">
      <a-form-model ref="ruleForm" :model="query" :rules="rules" v-bind="layout">
        <!-- <a-form-model-item
          has-feedback
          label="发票抬头"
          prop="taxInfoId"
        >
          <a-select v-model="query.taxInfoId" @change="onContract" placeholder="请选择发票抬头" >
            <a-select-option v-for="item in industry" :key="item.id">
              {{ item.accountName +'-'+ item.accountBank +'-'+ item.accountCode +'-'+ item.registerAddress }}
            </a-select-option>
          </a-select>
        </a-form-model-item> -->
        <a-form-model-item has-feedback label="发票抬头">
          <span>{{ industry.accountName }}</span>
        </a-form-model-item>
        <!-- <span @click="newInvoices" class="fapiao">新增抬头</span> -->
        <!-- <div
          class="taitouxianshi"
          v-if="valse != ''">
          <p>抬头：{{ accountNames }}</p>
          <p>开户行：{{ accountBanks }}</p>
          <p>银行账号：{{ accountCodes }}</p>
          <p>注册地址：{{ registerAddresss }}</p>
          <p>税号：{{ taxNumbers }}</p>
          <p>电话：{{ taxPhones }}</p>
        </div> -->
        <!-- 充值记录 -->
        <a-form-model-item has-feedback label="充值记录" prop="chargeId">
          <a-select
            :disabled="isAdd === 1 ? false : true"
            v-model="query.chargeId"
            @change="chargeIdChang"
            placeholder="请选择充值记录"
          >
            <a-select-option v-for="item in incomeAmountPs" :key="item.id" :value="item.id">
              {{ item.partyName + '-' + item.incomeAmount }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- 发票金额 -->
        <a-form-model-item has-feedback label="发票金额" prop="invoiceMoney">
          <a-input v-model.trim="query.invoiceMoney" autocomplete="off" placeholder="请选择充值记录" disabled />
        </a-form-model-item>
        <a-form-model-item has-feedback label="发票类型" prop="invoiceType">
          <span>{{ industry.invoiceType === '1' ? '增值税专用发票' : '增值税普通发票' }}</span>
        </a-form-model-item>
        <!-- <a-form-model-item
          has-feedback
          label="发票类型"
          prop="invoiceType"
        >
          <a-select v-model="query.invoiceType" placeholder="请选择发票类型">
            <a-select-option value="1"> 专票 </a-select-option>
            <a-select-option value="2"> 普票 </a-select-option>
          </a-select>
        </a-form-model-item> -->
        <!-- <a-form-model-item
          has-feedback
          label="账户类型"
        >
          <span v-if="isAdd === 1">平台</span>
          <span v-if="isAdd === 2">{{ partyTypeText }}</span>
        </a-form-model-item> -->
        <!-- <a-form-model-item
          has-feedback
          label="收入模式"
        >
          <span v-if="isAdd === 1">平台收入</span>
          <span v-if="isAdd === 2">{{ incomeTypeText }}</span>
        </a-form-model-item> -->
        <!-- <a-form-model-item
          has-feedback
          label="结算模式"
          prop="settleModel"
        >
          <span v-if="isAdd === 1">平台结算</span>
          <span v-if="isAdd === 2">{{ settleModelText }}</span>
        </a-form-model-item> -->
        <!-- <a-form-model-item
          has-feedback
          label="结算状态"
          prop="settleSts"
        >
          <a-select v-model="query.settleSts" placeholder="请选择结算状态" >
            <a-select-option value="0"> 待结算 </a-select-option>
            <a-select-option value="1"> 已结算 </a-select-option>
          </a-select>
        </a-form-model-item> -->
        <template v-if="isAdd === 2">
          <a-form-model-item has-feedback label="发票" prop="file" v-if="getJurisdiction()">
            <a-upload
              :action="uploadUrl"
              name="file"
              list-type="picture-card"
              :show-upload-list="false"
              :before-upload="beforeUpload"
              @change="handleChange"
              :headers="header"
            >
              <img
                :key="query.attachmentUrls"
                v-if="query.attachmentUrls"
                :src="resourceUrl + query.attachmentUrls"
                style="width: 120px; height: 100%"
              />

              <!-- <div v-for="item in imagesPush" :key="item" >
                <img v-if="item" :src="resourceUrl + item" alt="发票" style="width: 120px" />
              </div> -->
              <div>
                <a-icon :type="loading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">Upload</div>
              </div>
            </a-upload>
          </a-form-model-item>
        </template>
        <template v-if="Number(query.invoiceSts) === 1 && role !== 'SUPER_ADMIN'">
          <a-form-model-item has-feedback label="发票" prop="file">
            <div class="creative_item_wrapper">
              <div class="el_image_box">
                <el-image
                  class="elImage"
                  style="height: 128px; max-width: var(--ad--creative--width)"
                  :src="resourceUrl + query.attachmentUrls"
                  :preview-src-list="[resourceUrl + query.attachmentUrls]"
                >
                </el-image>
                <div class="modal">
                  <a-icon
                    class="deleteIcon"
                    type="eye"
                    @click="() => handlePreviewImg(resourceUrl + query.attachmentUrls)"
                  />
                </div>
              </div>
            </div>
          </a-form-model-item>
        </template>
        <a-form-model-item has-feedback label="备注">
          <a-input v-model.trim="query.remark" autocomplete="off" type="textarea" placeholder="请输入备注" />
        </a-form-model-item>
      </a-form-model>
    </div>
    <InvoicLooked
      v-if="addPlaceVisibles"
      :invoic="invoic"
      :visible="addPlaceVisibles"
      @modalCancel="addPlaceVisibles = false"
      @changePlaces="changePlaces"
    />
    <a-modal dialogClass="img_modal" :visible="previewVisibleModel" :footer="null" @cancel="handleCanPreview">
      <img alt="example" style="max-width: 100%" :src="previewImage" />
    </a-modal>
  </a-modal>
</template>
<script>
import { incomeList } from '@/api/media'
import { taxInfoList, invoiceAdd, invoiceUpdate } from '@/api/financialManagementA'
import { mapState } from 'vuex'
import InvoicLooked from '../invoicLooked'
export default {
  components: { InvoicLooked },
  data () {
    return {
      partyTypeText: '',
      settleModelText: '',
      incomeTypeText: '',
      invoic: {},
      valse: '',
      addPlaceVisibles: false,
      accountName: '',
      accountBanks: '',
      accountCodes: '',
      registerAddresss: '',
      taxNumbers: '',
      taxPhones: '',
      imagesPush: [],
      contractTypes: '',
      agentUser: [],
      tps: 1,
      datts: [],
      defectSearchForm: {},
      imagesUrl: '',
      url: '...',
      fileList: [],
      sysAgent: [],
      businessUser: [],
      industry: {},
      fieldNames: { label: 'name', value: 'id', children: 'children' },
      pid: [],
      uploadUrl: process.env.VUE_APP_API_BASE_URL + 'common/upload',
      loading: false,
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
      },
      query: {
        // incomeType: 2,
        // settleModel: 2
      },
      rules: {
        chargeId: [{ required: true, message: '请选择充值记录', trigger: 'change' }],
        // taxInfoId: [{ required: true, message: '请选择抬头', trigger: 'change' }],
        // invoiceMoney: [{ required: true, message: '请输入发票金额', trigger: 'blur' }],
        // invoiceType: [{ required: true, message: '请选择发票类型', trigger: 'change' }],
        file: [{ required: true, trigger: 'change', validator: this.uploadFileChange }]
        // settleSts: [{ required: true, message: '请选择结算状态', trigger: 'change' }],
        // remark: [{ required: true, message: '请输入备注', trigger: 'blur' }]
        // incomeType: [{ required: true, message: '请选择收入模式', trigger: 'change' }]
      },
      appList: [],
      positionList: [],
      templateList: [],
      names: '',
      incomeAmountPs: [],
      previewVisibleModel: false,
      previewImage: []
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Number,
      default: 0
    },
    appId: {
      default: '',
      type: String
    },
    placeInfo: {
      default: () => ({}),
      type: Object
    },
    supplier: {
      type: Number,
      default: 0
    }
  },
  watch: {
    placeInfo: {
      handler (val) {
        if (this.isAdd === 1) {
          this.names = '新增发票'
        } else if (this.isAdd === 2) {
          this.names = '修改发票'
          this.query = JSON.parse(JSON.stringify(val))
          this.query.chargeId = val.chargeId * 1
          this.industry.accountName = val.companyName
          this.industry.partyType = val.partyType
          const list = {
            1: '广告主',
            2: '代理商',
            3: '广告平台',
            4: '平台媒体'
          }
          const list1 = {
            1: '直客收入',
            2: '平台收入'
          }
          const list2 = {
            1: '预收货款',
            2: '平台结算'
          }
          this.settleModelText = list2[val.settleModel]
          this.incomeTypeText = list1[val.incomeType]
          this.partyTypeText = list[val.partyType]
          // this.imagesPush = val.attachmentUrls.split(',')
        }
      },
      deep: true,
      immediate: true
    },
    appId: {
      handler (val) {
        if (val) {
          this.query.appId = val
        }
      },
      immediate: true
    }
  },
  created () {},
  mounted () {
    if (this.isAdd === 1) {
      this.getTaxInfoList()
    }
    this.getIncomeList()
  },
  computed: {
    ...mapState({
      token: (state) => state.user.token,
      resourceUrl: (state) => state.autoweb.resourceUrl,
      role: (state) => state.user.roles[0]
    }),
    header () {
      return {
        Authorization: this.token
      }
    },
    addPlaceVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    // 点击图片取消
    handleCanPreview () {
      this.previewVisibleModel = false
    },
    // 点击图片
    handlePreviewImg (src) {
      this.previewVisibleModel = true
      this.previewImage = src
    },
    // 发票上传判断
    getJurisdiction () {
      const partyType = this.industry.partyType
      return (
        (partyType === '4' && this.role === 'SUPER_ADMIN') ||
        ((partyType === '1' || partyType === '2') && this.role !== 'SUPER_ADMIN')
      )
    },
    // 选择充值记录
    chargeIdChang (value) {
      this.incomeAmountPs.forEach((item) => {
        if (item.id === value) {
          this.query.invoiceMoney = item.incomeAmount
        }
      })
    },
    uploadFileChange (rule, value, callback) {
      if (this.query.attachmentUrls.length === 0) {
        return callback(new Error('请上传图片'))
      } else {
        return true
      }
    },
    newInvoices (type) {
      this.addPlaceVisibles = true
    },
    changePlaces () {
      this.timer = setTimeout(() => {
        this.getTaxInfoList()
      }, 1000)
    },
    onChange (date, dateString) {
      this.query.times = dateString
      this.query.contractStartTime = this.query.times[0]
      this.query.contractEndTime = this.query.times[1]
    },
    // 发票抬头
    async getTaxInfoList () {
      const res = await taxInfoList()
      if (res.code === 200) {
        this.industry = res?.data[0] || {}
      }
    },
    // 充值记录
    async getIncomeList () {
      const res = await incomeList({
        type: this.isAdd === 1 ? 1 : 0,
        partyId: this.query.partyId,
        partyType: this.query.partyType
      })
      if (res.code === 200) {
        this.incomeAmountPs = res?.data
      }
    },
    changeTradeId (val) {
      this.query.advertiserIndustry = val[1] * 1
      if (val.length > 0) {
        this.query.tradePid = val[0]
        this.query.tradeId = val[1]
      }
    },
    handleCancel () {
      this.isAdd = ''
      this.addPlaceVisible = false
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.isAdd) {
            if (this.isAdd === 1) {
              const { chargeId = '', invoiceMoney = '', remark = '' } = this.query
              const { id = '', invoiceType = '' } = this.industry
              const resp = await invoiceAdd({
                taxInfoId: id,
                invoiceType: invoiceType,
                chargeId,
                invoiceMoney,
                remark
              })
              if (resp.code === 200) {
                this.$message.success('添加成功!')
                this.$emit('changeAppInfo', 'add')
              }
            } else if (this.isAdd === 2) {
              const resp = await invoiceUpdate(this.query)
              if (resp.code === 200) {
                this.$message.success('修改成功!')
                this.$emit('changeAppInfo', 'add')
              }
            }
            this.query = {}
            this.$emit('changePlace')
            this.addPlaceVisible = false
          } else {
            return false
          }
        }
      })
    },

    handleChange (info) {
      if (info.file.status === 'uploading') {
        this.loading = true
        return
      }
      if (info.file.status === 'done') {
        this.query.attachmentUrls = info.file.response.data
        this.loading = false
      }
      // if (info.file.status === 'done') {
      //   this.imagesPush.push(info.file.response.data)
      //   // this.query.attachmentUrls = []
      //   this.query.attachmentUrls = this.imagesPush.join(',')
      //   this.loading = false
      // }
    },
    beforeUpload (file) {
      this.datts = file
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$message.error('请上传图片文件！')
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('请上传小于2M的图片!')
      }
      return isJpgOrPng && isLt2M
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/mixins/redefineAnt.less';
.batch_advertiser_modal {
  .ant-modal-body {
    // background-color: #eee;
    .add_advertiser_wrapper {
      height: 30%;
      overflow-y: auto;
      border-left: 10px solid #fff;
      border-top: 10px solid #fff;
      border-bottom: 10px solid #fff;
      background-color: #fff;
      border-radius: 5px;
      position: relative;
    }
  }
}
.fapiao {
  color: @primary-color;
  position: absolute;
  top: 40px;
  left: 145px;
}
.taitouxianshi {
  width: 58%;
  background-color: #d4d3d3;
  padding: 10px;
  margin-bottom: 10px;
  margin-left: 145px;
}
.creative_item_wrapper {
  width: 270px;
  height: 130px;
  .noneImg {
    width: 200px;
    height: 100px;
    background: #fafafa;
  }
  .el_image_box {
    background: #fafafa;
    border: 1px dashed #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      .modal {
        opacity: 1;
      }
    }
    .modal {
      opacity: 0;
      background: rgba(0, 0, 0, 0.1);
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 270px;
      height: 130px;
      .deleteIcon {
        margin: 5px;
        cursor: pointer;
        color: #333;
        font-size: 20px;
        &:hover {
          color: @primary-color;
        }
      }
    }
  }
}
::v-deep {
  .img_modal {
    top: 33%;
  }
}
</style>
